import { init as initApm } from '@elastic/apm-rum';

const apm = initApm({
  serviceName: 'minimal-apm-source-map-app',
  serverUrl: 'https://apm-tooling.vumacam.co.za',
  serviceVersion: '1.0',
  environment: 'local-development'
});

function generateError() {
  // This function will generate an error for testing source maps
  throw new Error('Test error for source maps');
}

document.addEventListener('DOMContentLoaded', () => {
  const button = document.createElement('button');
  button.textContent = 'Generate Error';
  button.onclick = generateError;
  document.body.appendChild(button);
});
